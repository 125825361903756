<template>
<section>
  <h3>{{myIp}}</h3>
</section>
</template>

<script>
export default {
  name: "MyIp",
  data(){
    return {
      myIp: ""
    }
  },
  mounted() {
    this.$http.get(this.$store.state.apiConfig + "/my-ip").then(response => {
      this.myIp = response.data
    }).catch(()=>{
      this.myIp = ""
    })
  }
}
</script>

<style scoped>
</style>