<template>
  <div class="my-ip">
    <div>This is your public IP address</div>
    <my-ip></my-ip>
  </div>
</template>
<script>
import MyIp from '@/components/MyIp.vue'
export  default {
  name:"MyIpPage",
  components: {MyIp}
}
</script>
